<template>
  <div class="container">
    <br />
    <div class="row" data-aos="fade-in" data-aos-duration="1500">
      <div class="col-md-12">
        <h3 class="Tangier_Bold">Contactez-moi</h3>
      </div>
      <div class="col-md-12">
        <form @submit.prevent="handleSubmit">
          <!-- <div class="row contact-card"> -->
            <div class="row">
              <div class="col-sm-12 col-md-6 mt-4">
                <b-alert v-if="inputcheck || time_check" variant="warning" show>
                  Vous avez prouvé que vous étiez un bot ! j'ai agi comme si vous
                  aviez envoyé le formulaire avec succès.
                </b-alert>

                <b-form-group
                  id="fieldset-horizontal"
                  label-for="name"
                  label="Nom et prénoms"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="$v.name.$dirty ? !$v.name.$error : null"
                    @keypress="startCheckBot()"
                    aria-describedby="name"
                    autofocus
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="fieldset-horizontal"
                  label-for="mail"
                  label="e-mail"
                >
                  <b-form-input
                    id="mail"
                    v-model="mail"
                    type="email"
                    :state="$v.mail.$dirty ? !$v.mail.$error : null"
                    aria-describedby="mail"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="fieldset-horizontal"
                  label-for="phone"
                  label="Téléphone"

                >
                  <b-form-input id="phone" v-model="phone"></b-form-input>
                </b-form-group>
              </div>
              <div class=" col-sm-12 col-md-6 mt-4">
                <!-- <b-form-group
                  id="fieldset-horizontal"
                  label="Piece"
                  label-for="file"
                >
                  <b-form-file id="file" type="file" v-model="file"></b-form-file>
                </b-form-group> -->
                <b-form-group
                  id="fieldset-horizontal"
                  label="Message"
                  label-for="Message"
                  rows="8"
                  max-rows="8"
                >
                  <b-form-textarea
                    id="Message"
                    v-model="message"
                    :state="$v.mail.$dirty ? !$v.mail.$error : null"
                    aria-describedby="mail"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  style="display: none"
                  id="fieldset-horizontal"
                  description="city"
                >
                  <b-form-input id="city" v-model="city"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 d-flex justify-content-center text-center send-button">
                <button
                  type="submit"
                  class="pr-btn"
                >
                  Envoyer
                </button>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "contact",
  data() {
    return {
      name: "",
      mail: "",
      phone: "",
      file: "",
      message: "",
      city: "",
      inputcheck: false,
      seconds: 0,
      tens: 0,
      Interval: "",
      time_check: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    mail: {
      required,
    },
    message: {
      required,
    },
  },
  methods: {
     handleSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        //error
      } else {
       this.check();

         if (!this.inputcheck && !this.time_check) {

           axios.post('https://backend-prc.comolapapaya.com/contact',
            {
              "email":this.mail,
              "name":this.name,
              "message":this.message,
              "phone":this.phone
            }
           )
           .then(
             ()=>{
               alert('Un courriel vient d\'être envoyé.')
                this.$router.push({
                    name:'home'
                })
             }
           )
           .catch(
             err => console.log({err})
           )
         }

      }
    },
    prc(){
        axios.post('https://backend-prc.comolapapaya.com/contact',{
            "email":"sataboum3@gmail.com",
            "name":"sata",
            "message":"ceci est un mail de test",
            "phone":"+65465464565464"
        })
        .then(test=> console.log(test))
        .catch(err=>console.error(err))
    },
    check() {
      if (this.city != "") {
        this.inputcheck = true;
      }
      if (this.seconds < 2) {
        this.time_check = true;
      }
    },
    startCheckBot() {
      clearInterval(this.Interval);
      this.Interval = setInterval(this.startTimer, 10);
    },
    startTimer() {
      this.tens++;
      if (this.tens > 99) {
        this.seconds++;
        this.tens = 0;
      }
    },
  },
};
</script>

<style scoped>
.content {
  min-height: 600px;
  max-width: 1300px;
  margin: auto;
}
.contact-card {
  margin-top: 20px;
  height: 400px;
  width: 900px;
  margin-bottom: 200px;
}
.send-button {
  padding: 40px;
}
#Message {
  height: 125px;
}

.pr-btn{
 background: linear-gradient(90deg, rgba(250,88,0,1) 0%, rgba(253,186,0,1) 100%);
 background-clip: padding-box;
 color: white;
 font-family: "Noto Sans";
 font-weight: 600;
 padding: 10px;
 border: solid 5px transparent;
 border-radius: 2em;
 width: 150px;
}
.pr-btn:hover{
  border: solid 5px transparent;
  background-image: linear-gradient(white,white), linear-gradient(to right, #fa5800, #fdba00);
  color: gray;
  font-family: "Noto Sans";
  background-clip: padding-box, border-box;
  background-origin: border-box;
}
.form-control:hover{
  box-shadow: 3px -1px 13px -7px #6295FE;
}

</style>